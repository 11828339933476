import React from 'react';
import { Location } from '@reach/router';
import styled from 'styled-components';
import { widthBp, sizes } from '../lib/styles';
import { ModalConsumer } from './ModalProvider';

const ConnectedMain = ({
  children,
  hideMenu,
  isMenuOpen,
  isHomeRoute,
}) => (
  <MainWrapper
    onClick={isMenuOpen && !isHomeRoute ? hideMenu : () => {}}
    isMenuOpen={isMenuOpen}
    isHomeRoute={isHomeRoute}
  >
    {children}
  </MainWrapper>
);

export const Main = ({ children }) => (
  <Location>
    {({ location }) => {
      const isHomeRoute = location.pathname === '/';
      return (
        <ModalConsumer>
          {({ isMenuOpen, hideMenu }) => (
            <ConnectedMain
              hideMenu={hideMenu}
              isMenuOpen={isMenuOpen}
              isHomeRoute={isHomeRoute}
            >
              {children}
            </ConnectedMain>
          )}
        </ModalConsumer>
      );
    }
  }
  </Location>
);

const MainWrapper = styled.main`
  position: relative;
  width: 100%;
  height: auto;
  transition: background 0.1s ease-in-out;
  @media (${widthBp(sizes.handheld)}) {
    @media (prefers-color-scheme: light) {
      ${({ isMenuOpen, isHomeRoute }) => isMenuOpen && !isHomeRoute && 'background-color: #f3f3f3;'}
    }
    @media (prefers-color-scheme: dark) {
      ${({ isMenuOpen, isHomeRoute }) => isMenuOpen && !isHomeRoute && 'background-color: #161719;'}
    }
  }
  @media (${widthBp(sizes.laptop)}) {
    display: flex;
    justify-content: center;
  }
`;

export const RelativeContainer = styled.section`
  position: relative;
  width: 100%;
  height: 100%;
  @media (${widthBp(sizes.laptop)}) {
    max-width: 1000px;
  }
  @media (${widthBp(sizes.desktop)}) {
    max-width: 1150px;
  }
  @media (${widthBp(sizes.huge)}) {
    max-width: 1250px;
  }
`;

export const PaddingWrap = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  padding-top: 90px;
  ${({ isMenuOpen }) => isMenuOpen && 'pointer-events: none;'}
  @media (${widthBp(sizes.laptop)}) {
    padding-left: 45px;
    padding-right: 45px;
  }
`;

export const DescriptionWrap = styled.section`
  position: relative;
  width: 100%;
  padding-left: 30px;
  padding-right: 30px;
  margin-bottom: 40px;
  @media (${widthBp(sizes.laptop)}) {
    margin-bottom: 60px;
    padding-left: 60px;
  }
  @media (${widthBp(sizes.desktop)}) {
    max-width: 84%;
  }
  :last-of-type {
    margin-bottom: 0;
  }
`;

export const Address = styled.address`
  position: relative;
  font-style: normal;
  width: 100%;
  padding-left: 30px;
  padding-right: 30px;
  margin-bottom: 40px;
  @media (${widthBp(sizes.laptop)}) {
    padding-left: 60px;
  }
`;
