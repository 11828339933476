/* eslint-disable global-require, max-len */
export default {
  id: 'plasticHabitat',
  path: '/plastic-habitat',
  nextPath: '/hangar-eden',
  aspectRatio: '66.67',
  images: [
    {
      url: require('../assets/images/plasticHabitat/fabianschroder-plastic_habitat-al_barsha_2.jpg'),
      urlMedium: require('../assets/images/plasticHabitat/fabianschroder-plastic_habitat-al_barsha_2-medium.jpg'),
      // caption: 'Al Barsha, 2017',
    }, {
      url: require('../assets/images/plasticHabitat/fabianschroder-plastic_habitat-jumeirah_park.jpg'),
      urlMedium: require('../assets/images/plasticHabitat/fabianschroder-plastic_habitat-jumeirah_park-medium.jpg'),
      // caption: 'Jumeirah Park, 2015',
    }, {
      url: require('../assets/images/plasticHabitat/fabianschroder-plastic_habitat-zaabeel.jpg'),
      urlMedium: require('../assets/images/plasticHabitat/fabianschroder-plastic_habitat-zaabeel-medium.jpg'),
      // caption: 'Zaabeel, 2015',
    }, {
      url: require('../assets/images/plasticHabitat/fabianschroder-plastic_habitat-pearl_jumeirah_2.jpg'),
      urlMedium: require('../assets/images/plasticHabitat/fabianschroder-plastic_habitat-pearl_jumeirah_2-medium.jpg'),
      // caption: 'Pearl Jumeirah, 2017',
    }, {
      url: require('../assets/images/plasticHabitat/fabianschroder-plastic_habitat-jumeirah_golf_estates_4.jpg'),
      urlMedium: require('../assets/images/plasticHabitat/fabianschroder-plastic_habitat-jumeirah_golf_estates_4-medium.jpg'),
      // caption: 'Jumeirah Golf Estates, 2015',
    }, {
      url: require('../assets/images/plasticHabitat/fabianschroder-plastic_habitat-mira_townhouses_community.jpg'),
      urlMedium: require('../assets/images/plasticHabitat/fabianschroder-plastic_habitat-mira_townhouses_community-medium.jpg'),
      // caption: 'Mira Townhouses Community, 2019',
    }, {
      url: require('../assets/images/plasticHabitat/fabianschroder-plastic_habitat-district_one.jpg'),
      urlMedium: require('../assets/images/plasticHabitat/fabianschroder-plastic_habitat-district_one-medium.jpg'),
      // caption: 'District 1, 2019',
    }, {
      url: require('../assets/images/plasticHabitat/fabianschroder-plastic_habitat-al_barsha_1.jpg'),
      urlMedium: require('../assets/images/plasticHabitat/fabianschroder-plastic_habitat-al_barsha_1-medium.jpg'),
      // caption: 'Al Barsha #2, 2017',
    }, {
      url: require('../assets/images/plasticHabitat/fabianschroder-plastic_habitat-falcon_city_of_wonders.jpg'),
      urlMedium: require('../assets/images/plasticHabitat/fabianschroder-plastic_habitat-falcon_city_of_wonders-medium.jpg'),
      // caption: 'Falcon City of Wonders, 2015',
    }, {
      url: require('../assets/images/plasticHabitat/fabianschroder-plastic_habitat-pearl_jumeirah_5.jpg'),
      urlMedium: require('../assets/images/plasticHabitat/fabianschroder-plastic_habitat-pearl_jumeirah_5-medium.jpg'),
      // caption: 'Pearl Jumeirah #2, 2017',
    }, {
      url: require('../assets/images/plasticHabitat/fabianschroder-plastic_habitat-mohammed_bin_rashid_city_2.jpg'),
      urlMedium: require('../assets/images/plasticHabitat/fabianschroder-plastic_habitat-mohammed_bin_rashid_city_2-medium.jpg'),
      // caption: 'Mohammed Bin Rashid City, 2017',
    }, {
      url: require('../assets/images/plasticHabitat/fabianschroder-plastic_habitat-untitled_2.jpg'),
      urlMedium: require('../assets/images/plasticHabitat/fabianschroder-plastic_habitat-untitled_2-medium.jpg'),
      // caption: 'Untitled #2, 2019',
    }, {
      url: require('../assets/images/plasticHabitat/fabianschroder-plastic_habitat-jumeirah_park_villa.jpg'),
      urlMedium: require('../assets/images/plasticHabitat/fabianschroder-plastic_habitat-jumeirah_park_villa-medium.jpg'),
      // caption: 'Jumeirah Park Villa, 2017',
    }, {
      url: require('../assets/images/plasticHabitat/fabianschroder-plastic_habitat-jumeirah_park_nova_2.jpg'),
      urlMedium: require('../assets/images/plasticHabitat/fabianschroder-plastic_habitat-jumeirah_park_nova_2-medium.jpg'),
      // caption: 'Jumeirah Park Nova, 2017',
    }, {
    //   url: require('../assets/images/plasticHabitat/fabianschroder-plastic_habitat-serena.jpg'),
    //   urlMedium: require('../assets/images/plasticHabitat/fabianschroder-plastic_habitat-serena-medium.jpg'),
    //   caption: 'Serena, 2019',
    // }, {

    //   url: require('../assets/images/plasticHabitat/fabianschroder-plastic_habitat-desert_village.jpg'),
    //   urlMedium: require('../assets/images/plasticHabitat/fabianschroder-plastic_habitat-desert_village-medium.jpg'),
    //   caption: 'Desert Village, 2019',
    // }, {

    //   url: require('../assets/images/plasticHabitat/fabianschroder-plastic_habitat-hayat_townhouses.jpg'),
    //   urlMedium: require('../assets/images/plasticHabitat/fabianschroder-plastic_habitat-hayat_townhouses-medium.jpg'),
    //   caption: 'Hayat Townhouses, 2019',
    // }, {
    //   url: require('../assets/images/plasticHabitat/fabianschroder-plastic_habitat-pearl_jumeirah.jpg'),
    //   urlMedium: require('../assets/images/plasticHabitat/fabianschroder-plastic_habitat-pearl_jumeirah-medium.jpg'),
    //   caption: 'Pearl Jumeirah, 2017',
    // }, {
    //   url: require('../assets/images/plasticHabitat/fabianschroder-plastic_habitat-dubai_studio_city.jpg'),
    //   urlMedium: require('../assets/images/plasticHabitat/fabianschroder-plastic_habitat-dubai_studio_city-medium.jpg'),
    //   caption: 'Dubai Studio City, 2019',
    // }, {
    //   url: require('../assets/images/plasticHabitat/fabianschroder-plastic_habitat-pearl_jumeirah_3.jpg'),
    //   urlMedium: require('../assets/images/plasticHabitat/fabianschroder-plastic_habitat-pearl_jumeirah_3-medium.jpg'),
    //   caption: 'Pearl Jumeirah #3, 2017',
    // }, {
    //   url: require('../assets/images/plasticHabitat/fabianschroder-plastic_habitat-mohammed_bin_rashid_city.jpg'),
    //   urlMedium: require('../assets/images/plasticHabitat/fabianschroder-plastic_habitat-mohammed_bin_rashid_city-medium.jpg'),
    //   caption: 'Mohammed Bin Rashid City, 2017',
    // }, {
    //   url: require('../assets/images/plasticHabitat/fabianschroder-plastic_habitat-jumeirah_park_2.jpg'),
    //   urlMedium: require('../assets/images/plasticHabitat/fabianschroder-plastic_habitat-jumeirah_park_2-medium.jpg'),
    //   caption: 'Jumeirah Park #2, 2015',
    // }, {
    //   url: require('../assets/images/plasticHabitat/fabianschroder-plastic_habitat-al_waha.jpg'),
    //   urlMedium: require('../assets/images/plasticHabitat/fabianschroder-plastic_habitat-al_waha-medium.jpg'),
    //   caption: 'Al Waha, 2019',
    // }, {
    //   url: require('../assets/images/plasticHabitat/fabianschroder-plastic_habitat-the_villa.jpg'),
    //   urlMedium: require('../assets/images/plasticHabitat/fabianschroder-plastic_habitat-the_villa-medium.jpg'),
    //   caption: 'The Villa, 2019',
    // }, {
    //   url: require('../assets/images/plasticHabitat/fabianschroder-plastic_habitat-umm_suqeim.jpg'),
    //   urlMedium: require('../assets/images/plasticHabitat/fabianschroder-plastic_habitat-umm_suqeim-medium.jpg'),
    //   caption: 'Umm Suqeim, 2019',
    // }, {

    //   url: require('../assets/images/plasticHabitat/fabianschroder-plastic_habitat-jumeirah_golf_estates.jpg'),
    //   urlMedium: require('../assets/images/plasticHabitat/fabianschroder-plastic_habitat-jumeirah_golf_estates-medium.jpg'),
    //   caption: 'Jumeirah Golf Estates, 2015',
    // }, {
    //   url: require('../assets/images/plasticHabitat/fabianschroder-plastic_habitat-pearl_jumeirah_4.jpg'),
    //   urlMedium: require('../assets/images/plasticHabitat/fabianschroder-plastic_habitat-pearl_jumeirah_4-medium.jpg'),
    //   caption: 'Pearl Jumeirah #4, 2017',
    // }, {
    //   url: require('../assets/images/plasticHabitat/fabianschroder-plastic_habitat-arabian_ranches.jpg'),
    //   urlMedium: require('../assets/images/plasticHabitat/fabianschroder-plastic_habitat-arabian_ranches-medium.jpg'),
    //   caption: 'Arabian Ranches, 2019',
    // }, {

    //   url: require('../assets/images/plasticHabitat/fabianschroder-plastic_habitat-jumeirah_park_nova.jpg'),
    //   urlMedium: require('../assets/images/plasticHabitat/fabianschroder-plastic_habitat-jumeirah_park_nova-medium.jpg'),
    //   caption: 'Jumeirah Park Nova #2, 2019',
    // }, {

    //   url: require('../assets/images/plasticHabitat/fabianschroder-plastic_habitat-jumeirah_golf_estates_2.jpg'),
    //   urlMedium: require('../assets/images/plasticHabitat/fabianschroder-plastic_habitat-jumeirah_golf_estates_2-medium.jpg'),
    //   caption: 'Jumeirah Golf Estates #2, 2015',
    // }, {

    //   url: require('../assets/images/plasticHabitat/fabianschroder-plastic_habitat-pearl_jumeirah_6.jpg'),
    //   urlMedium: require('../assets/images/plasticHabitat/fabianschroder-plastic_habitat-pearl_jumeirah_6-medium.jpg'),
    //   caption: 'Pearl Jumeirah #6, 2017',
    // }, {
    //   url: require('../assets/images/plasticHabitat/fabianschroder-plastic_habitat-jumeirah_golf_estates_3.jpg'),
    //   urlMedium: require('../assets/images/plasticHabitat/fabianschroder-plastic_habitat-jumeirah_golf_estates_3-medium.jpg'),
    //   caption: 'Jumeirah Golf Estates #3, 2015',
    // }, {

    //   url: require('../assets/images/plasticHabitat/fabianschroder-plastic_habitat-serena_residential_communitiy.jpg'),
    //   urlMedium: require('../assets/images/plasticHabitat/fabianschroder-plastic_habitat-serena_residential_communitiy-medium.jpg'),
    //   caption: 'Serena Residential Community, 2019',
    // }, {
    //   url: require('../assets/images/plasticHabitat/fabianschroder-plastic_habitat-jebel_ali_hills_meraas.jpg'),
    //   urlMedium: require('../assets/images/plasticHabitat/fabianschroder-plastic_habitat-jebel_ali_hills_meraas-medium.jpg'),
    //   caption: 'Jebel Ali Hills Meraas, 2019',
    // }, {

      url: require('../assets/images/views/fabianschroder-views-tallinn_fototallinn.jpg'),
      urlMedium: require('../assets/images/views/fabianschroder-views-tallinn_fototallinn-medium.jpg'),
      caption: 'Foto Tallinn, Tallinn, EE, 2019',
      link: 'https://www.fototallinn.ee/en/participant/fabian-schroder-2/',
      isGalleryImage: true,
    // }, {

    //   url: require('../assets/images/plasticHabitat/fabianschroder-plastic_habitat-al_barsha_4.jpg'),
    //   urlMedium: require('../assets/images/plasticHabitat/fabianschroder-plastic_habitat-al_barsha_4-medium.jpg'),
    //   caption: 'Al Barsha #4, 2017',
    // }, {
    //   url: require('../assets/images/plasticHabitat/fabianschroder-plastic_habitat-al_barsha_3.jpg'),
    //   urlMedium: require('../assets/images/plasticHabitat/fabianschroder-plastic_habitat-al_barsha_3-medium.jpg'),
    //   caption: 'Al Barsha #3, 2017',
    // }, {
    //   url: require('../assets/images/plasticHabitat/fabianschroder-plastic_habitat-jumeirah_golf_estates_5.jpg'),
    //   urlMedium: require('../assets/images/plasticHabitat/fabianschroder-plastic_habitat-jumeirah_golf_estates_5-medium.jpg'),
    //   caption: 'Jumeirah Golf Estates #5, 2015',
    // }, {
      // url: require('../assets/images/plasticHabitat/fabianschroder-plastic_habitat-untitled_3.jpg'),
      // urlMedium: require('../assets/images/plasticHabitat/fabianschroder-plastic_habitat-untitled_3-medium.jpg'),
      // caption: 'Untitled #3, 2019',
    // }, {
    //   url: require('../assets/images/plasticHabitat/fabianschroder-plastic_habitat-al_barsha_5.jpg'),
    //   urlMedium: require('../assets/images/plasticHabitat/fabianschroder-plastic_habitat-al_barsha_5-medium.jpg'),
    //   caption: 'Al Barsha #5, 2017',
    // }, {

    //   url: require('../assets/images/plasticHabitat/fabianschroder-plastic_habitat-pearl_jumeirah_12.jpg'),
    //   urlMedium: require('../assets/images/plasticHabitat/fabianschroder-plastic_habitat-pearl_jumeirah_12-medium.jpg'),
    //   caption: 'Pearl Jumeirah #12, 2017',
    // }, {
    //   url: require('../assets/images/plasticHabitat/fabianschroder-plastic_habitat-pearl_jumeirah_7.jpg'),
    //   urlMedium: require('../assets/images/plasticHabitat/fabianschroder-plastic_habitat-pearl_jumeirah_7-medium.jpg'),
    //   caption: 'Pearl Jumeirah #7, 2017',
    // }, {
    //   url: require('../assets/images/plasticHabitat/fabianschroder-plastic_habitat-pearl_jumeirah_9.jpg'),
    //   urlMedium: require('../assets/images/plasticHabitat/fabianschroder-plastic_habitat-pearl_jumeirah_9-medium.jpg'),
    //   caption: 'Pearl Jumeirah #9, 2017',
    // }, {

    //   url: require('../assets/images/plasticHabitat/fabianschroder-plastic_habitat-pearl_jumeirah_11.jpg'),
    //   urlMedium: require('../assets/images/plasticHabitat/fabianschroder-plastic_habitat-pearl_jumeirah_11-medium.jpg'),
    //   caption: 'Pearl Jumeirah #11, 2017',
    // }, {
    //   url: require('../assets/images/plasticHabitat/fabianschroder-plastic_habitat-pearl_jumeirah_13.jpg'),
    //   urlMedium: require('../assets/images/plasticHabitat/fabianschroder-plastic_habitat-pearl_jumeirah_13-medium.jpg'),
    //   caption: 'Pearl Jumeirah #13, 2019',
    // }, {
    //   url: require('../assets/images/plasticHabitat/fabianschroder-plastic_habitat-pearl_jumeirah_14.jpg'),
    //   urlMedium: require('../assets/images/plasticHabitat/fabianschroder-plastic_habitat-pearl_jumeirah_14-medium.jpg'),
    //   caption: 'Pearl Jumeirah #14, 2019',
    //   aspectRatio: '80',
    },
  ],
};
