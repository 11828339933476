import React from 'react';
import styled from 'styled-components';
import { Location } from '@reach/router';

import BurgerIcon from './BurgerIcon';
import { widthBp, sizes } from '../../lib/styles';
import { ModalConsumer } from '../ModalProvider';
import colors from '../../lib/colors';

const Burger = () => (
  <Location>
    {({ location }) => {
      const isHomeRoute = location.pathname === '/';
      return (
        <ModalConsumer>
          {({ isMenuOpen, showMenu, hideMenu }) => (
            <React.Fragment>
              <Button
                name={'Menu'}
                isMenuOpen={isMenuOpen}
                isHomeRoute={isHomeRoute}
                tabIndex="0"
                aria-label={isMenuOpen ? 'Close menu' : 'Open menu'}
                onClick={isMenuOpen ? hideMenu : showMenu}
              >
                <BurgerIcon isOpen={isMenuOpen} />
              </Button>
              <noscript>
                <Button
                  as="a"
                  href="/"
                  name={'Menu'}
                  tabIndex="0"
                  aria-label={isMenuOpen ? 'Close menu' : 'Open menu'}
                >
                  <BurgerIcon isOpen={isMenuOpen} />
                </Button>
              </noscript>
            </React.Fragment>
          )}
        </ModalConsumer>
      );
    }}
  </Location>
);

const Button = styled.button`
  position: fixed;
  outline: none;
  z-index: 3;
  bottom: 40px;
  left: 30px;
  border: 0;
  padding: 11px 9px;
  user-select: none;
  cursor: pointer;
  ${({ isHomeRoute }) => isHomeRoute && 'display: none;'};
  background: white;
  span { background: black; }
  @media (prefers-color-scheme: light) {
    background: white;
    span { background: black; }
  }
  @media (prefers-color-scheme: dark) {
    background: ${colors.dark.background};
    span { background: white; }
  }
  @media (${widthBp(sizes.laptop)}) {
    left: inherit;
    bottom: 90px;
    background: none;
    span { background: black; }
    &:hover, &:focus {
      background-color: black;
      span { background: white; }
    }
    @media (prefers-color-scheme: light) {
      background: none;
      span { background: black; }
      &:hover, &:focus {
        background-color: black;
        span { background: white; }
      }
    }
    @media (prefers-color-scheme: dark) {
      background: none;
      span { background: white; }
      &:hover, &:focus {
        background-color: white;
        span { background: black; }
      }

    }

  }`;

export default Burger;
