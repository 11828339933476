import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const BurgerIconWrap = styled.div`
  width: 28px;
  height: 22px;
  position: relative;
`;

const Line = styled.span`
  display: block;
  position: absolute;
  height: 5px;
  width: 100%;
  left: 0;
  transition: transform 0.1s ease-in-out;
  &:nth-child(1) {
    top: 0;
    ${props => props.isOpen && `
      top: 8px;
      width: 0%;
      left: 50%;
    `}
  }
  &:nth-child(2) {
    top: 9px;
    ${props => props.isOpen && `
      transform: rotate(45deg);
    `} 
  }
  &:nth-child(3) {
    top: 9px;
    ${props => props.isOpen && `
      transform: rotate(-45deg);
    `} 
  }
  &:nth-child(4) {
    top: 18px;
    ${props => props.isOpen && `
      top: 8px;
      width: 0%;
      left: 50%;
    `}
  }
`;

const BurgerIcon = ({ isOpen }) => (
  <BurgerIconWrap>
    <Line isOpen={isOpen} />
    <Line isOpen={isOpen} />
    <Line isOpen={isOpen} />
    <Line isOpen={isOpen} />
  </BurgerIconWrap>
);

BurgerIcon.propTypes = {
  isOpen: PropTypes.bool.isRequired,
};

export default BurgerIcon;
