/* eslint-disable global-require, max-len */
export default {
  id: 'anthropos',
  path: '/make-it-rain',
  nextPath: '/plastic-habitat',
  aspectRatio: '66.67',
  images: [
    {
      url: require('../assets/images/anthropos/fabianschroder-anthropos-golf_course.jpg'),
      urlMedium: require('../assets/images/anthropos/fabianschroder-anthropos-golf_course-medium.jpg'),
      // caption: 'Golf Lawn, 2018',
    }, {
      url: require('../assets/images/anthropos/fabianschroder-anthropos-ski_slope.jpg'),
      urlMedium: require('../assets/images/anthropos/fabianschroder-anthropos-ski_slope-medium.jpg'),
      // caption: 'Ski Slope, 2019',
    },
    // {
    //   url: require('../assets/images/anthropos/fabianschroder-anthropos-the_beach.jpg'),
    //   urlMedium: require('../assets/images/anthropos/fabianschroder-anthropos-the_beach-medium.jpg'),
    // caption: 'The New Beach, 2018',
    // },
    //  {
    //   url: require('../assets/images/anthropos/fabianschroder-anthropos-ski_hall.jpg'),
    //   urlMedium: require('../assets/images/anthropos/fabianschroder-anthropos-ski_hall-medium.jpg'),
    //   caption: 'Ski Hall, 2017',
    // }, {
    //   url: require('../assets/images/anthropos/fabianschroder-anthropos-waterpark.jpg'),
    //   urlMedium: require('../assets/images/anthropos/fabianschroder-anthropos-waterpark-medium.jpg'),
    //   caption: 'Waterpark, 2020',
    // }, {
    //   url: require('../assets/images/views/fabianschroder-views-berlin_urban_challenges.jpg'),
    //   urlMedium: require('../assets/images/views/fabianschroder-views-berlin_urban_challenges-medium.jpg'),
    //   caption: 'Urban Challenges - PEP photography, BPart, Berlin, DE, 2020',
    //   aspectRatio: '72.14',
    //   isGalleryImage: true,
    // },
  ],
};
