import React from 'react';
import { Transition } from 'react-spring';
import styled from 'styled-components';

import { widthBp, sizes } from '../../lib/styles';
import MenuContent from './MenuContent';
import { ModalConsumer } from '../ModalProvider';
import colors from '../../lib/colors';

const MenuAnimation = () => (
  <ModalConsumer>
    {({ isMenuOpen, hideMenu }) => (
      <Transition
        onKeyDown={event => event.keyCode === 27 && hideMenu}
        items={isMenuOpen}
        from={{ y: -100 }}
        enter={{ y: 0 }}
        leave={{ y: -100 }}
        config={{
          tension: 250,
          friction: 28,
        }}
      >
        {/* eslint-disable no-shadow, react/destructuring-assignment, react/prop-types */}
        {isMenuOpen => isMenuOpen && (
          props => (
            <MenuWrap
              style={{
                transform: `translateX(${props.y}%)`,
                WebkitTransform: `translateX(${props.y}%)`,
                msTransform: `translateX(${props.y}%)`,
              }}
            >
              <MenuContent />
            </MenuWrap>
          ))
        }
      </Transition>
    )}
  </ModalConsumer>
);

const MenuWrap = styled.nav`
  position: fixed;
  display: flex;
  top: 0;
  left: 0;
  bottom: 0;
  z-index: 2;
  overflow-y: auto;
  display: flex;
  flex-direction: row;
  width: 100%;
  background: white;
  color: black;
  @media (prefers-color-scheme: light) {
    background: white;
    color: black;
  }
  @media (prefers-color-scheme: dark) {
    background: ${colors.dark.background};
    color: white;
  }

  @media (${widthBp(sizes.handheld)}) {
    width: auto;
  }
`;

export default MenuAnimation;
