/* eslint-disable global-require, max-len */

export default {
  path: '/about',
  nextPath: '/',
  me: {
    imageUrl: require('../assets/images/me/fabianschroder.jpg'),
  },
  education: ['maBxl', 'baVienna', 'baAntwerp'],
  shows: [
    'fotofestivalLeiden_two',
    'urbanChallenges',
    'timesUnderPressure',
    'fotoTallin',
    'tropicana404',
    'popMagazin',
    'raumteiler',
    'fotofestivalLeiden_one',
    'blackMountainCollege',
  ],
  texts: ['pepPhotograohy', 'photonews', 'chrisKuhn'],
};
