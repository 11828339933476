
import universal, { setHasBabelPlugin } from '/opt/build/repo/node_modules/react-universal-component/dist/index.js'


setHasBabelPlugin()

const universalOptions = {
  loading: () => null,
  error: props => {
    console.error(props.error);
    return <div>An error occurred loading this page's template. More information is available in the console.</div>;
  },
}

const t_0 = universal(import('../src/views/404'), universalOptions)
const t_1 = universal(import('../src/views/Home'), universalOptions)
const t_2 = universal(import('../src/views/Anthropos'), universalOptions)
const t_3 = universal(import('../src/views/InNatura'), universalOptions)
const t_4 = universal(import('../src/views/PlasticHabitat'), universalOptions)
const t_5 = universal(import('../src/views/HangarEden'), universalOptions)
const t_6 = universal(import('../src/views/RecreationNow'), universalOptions)
const t_7 = universal(import('../src/views/About'), universalOptions)
const t_8 = universal(import('../src/views/Legal'), universalOptions)


// Template Map
export default {
  '../src/views/404': t_0,
'../src/views/Home': t_1,
'../src/views/Anthropos': t_2,
'../src/views/InNatura': t_3,
'../src/views/PlasticHabitat': t_4,
'../src/views/HangarEden': t_5,
'../src/views/RecreationNow': t_6,
'../src/views/About': t_7,
'../src/views/Legal': t_8
}

export const notFoundTemplate = "../src/views/404"
