import { css } from 'styled-components';

export const sizes = {
  handheld: 736,
  laptop: 1025,
  desktop: 1400,
  huge: 1600,
};

export const constants = {
  colMaxWidth: '66rem',
};

export function widthBp(pxSize, max = false) {
  // use em in breakpoints to work properly cross-browser and support users
  // changing their browsers font-size: https://zellwk.com/blog/media-query-units/
  const px = max ? pxSize - 1 : pxSize;
  const emSize = px / 16;
  const bp = max ? 'max-width' : 'min-width';
  return `${bp}: ${emSize}em`;
}

export const media = Object.keys(sizes).reduce((accumulator, label) => {
  accumulator[label] = (...args) => css`
    @media (${widthBp(sizes[label])}) {
      ${css(...args)}
    }
  `;
  return accumulator;
}, {});

export const serif = `
  font-family: 'Source Serif Pro', serif;
  line-height: 1.5;
  letter-spacing: 0.002em;
`;
