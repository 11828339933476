/* eslint-disable global-require, max-len */
export default {
  id: 'inNatura',
  path: '/in-natura',
  nextPath: '/make-it-rain',
  aspectRatio: '66.67',
  images: [
    {
      url: require('../assets/images/inNatura/fabianschroder-in_natura-dinosaurs.jpg'),
      urlMedium: require('../assets/images/inNatura/fabianschroder-in_natura-dinosaurs-medium.jpg'),
      // caption: 'Dinosaurs, 2019',
    },
    {
      url: require('../assets/images/inNatura/fabianschroder-in_natura-desert.jpg'),
      urlMedium: require('../assets/images/inNatura/fabianschroder-in_natura-desert-medium.jpg'),
      // caption: 'Desert, 2019',
    }, {
      url: require('../assets/images/inNatura/fabianschroder-in_natura-trees.jpg'),
      urlMedium: require('../assets/images/inNatura/fabianschroder-in_natura-trees-medium.jpg'),
      // caption: 'Trees, 2017',
    }, {
      url: require('../assets/images/inNatura/fabianschroder-in_natura-cats.jpg'),
      urlMedium: require('../assets/images/inNatura/fabianschroder-in_natura-cats-medium.jpg'),
      // caption: 'Cats, 2019',
      isVertical: true,
      aspectRatio: '133',
    }, {
      url: require('../assets/images/inNatura/fabianschroder-in_natura-sky3.jpg'),
      urlMedium: require('../assets/images/inNatura/fabianschroder-in_natura-sky3-medium.jpg'),
      // caption: 'Sky, 2019',
      isVertical: true,
      aspectRatio: '150',
    }, {
    //   url: require('../assets/images/inNatura/fabianschroder-in_natura-sunflowers.jpg'),
    //   urlMedium: require('../assets/images/inNatura/fabianschroder-in_natura-sunflowers-medium.jpg'),
    //   // caption: 'Sunflowers, 2019',
    // }, {
    //   url: require('../assets/images/inNatura/fabianschroder-in_natura-golf.jpg'),
    //   urlMedium: require('../assets/images/inNatura/fabianschroder-in_natura-golf-medium.jpg'),
    //   // caption: 'Trees, 2017',
    // }, {
      url: require('../assets/images/inNatura/fabianschroder-in_natura-tree2.jpg'),
      urlMedium: require('../assets/images/inNatura/fabianschroder-in_natura-tree2-medium.jpg'),
      // caption: 'Tree, 2019',
      isVertical: true,
      aspectRatio: '133',
    }, {
      url: require('../assets/images/inNatura/fabianschroder-in_natura-shark.jpg'),
      urlMedium: require('../assets/images/inNatura/fabianschroder-in_natura-shark-medium.jpg'),
      // caption: 'Shark, 2019',
    }, {
      url: require('../assets/images/inNatura/fabianschroder-in_natura-boat.jpg'),
      urlMedium: require('../assets/images/inNatura/fabianschroder-in_natura-boat-medium.jpg'),
      // caption: 'Boat, 2019',
    }, {
      url: require('../assets/images/inNatura/fabianschroder-in_natura-plane.jpg'),
      urlMedium: require('../assets/images/inNatura/fabianschroder-in_natura-plane-medium.jpg'),
      // caption: 'Plane, 2019',
    },
  ],
};
