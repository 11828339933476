import React from 'react';
import { Root, Routes } from 'react-static';

import ModalProvider from './components/ModalProvider';
import GlobalStyle from './lib/GlobalStyle';
import Title from './components/Title';
import Burger from './components/Burger';
import { Main, RelativeContainer } from './components/layout';

function App() {
  return (
    <Root>
      <ModalProvider>
        <GlobalStyle />
        <Main>
          <RelativeContainer>
            <Title />
            <Burger />
            <Routes />
          </RelativeContainer>
        </Main>
      </ModalProvider>
    </Root>
  );
}

export default App;
