export default {
  light: {
    background: 'white',
    text: '#2d2d2d',
  },
  dark: {
    background: 'rgb(29, 30, 32)',
    text: '#bdbdbf',
  },
};
