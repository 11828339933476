import React, { createContext } from 'react';
import PropTypes from 'prop-types';

import Menu from './Menu';

const ModalContext = createContext({
  showMenu: () => {},
  hideMenu: () => {},
});

export const ModalConsumer = ModalContext.Consumer;

class ModalProvider extends React.Component {
  /* eslint-disable react/sort-comp */
  /* eslint-disable react/no-unused-state */
  showMenu = () => {
    this.setState({
      isMenuOpen: true,
    });
  };

  /* eslint-disable react/no-unused-state */
  hideMenu = () => {
    this.setState({
      isMenuOpen: false,
    });
  }

  /* eslint-disable react/no-unused-state */
  state = {
    isMenuOpen: false,
    showMenu: this.showMenu,
    hideMenu: this.hideMenu,
  };

  /* eslint-disable no-undef */
  lockBodyScrolling() {
    if (typeof document !== 'undefined') {
      this.appWrapper = document.getElementById('body');
      // Get scroll position to be able to restore it in onCloseModal
      this.bodyScrollPos = document.body.scrollTop
        || document.documentElement.scrollTop || 0;
      this.appWrapper.style.position = 'fixed';
      this.appWrapper.style.top = `-${this.bodyScrollPos}px`;
    }
  }

  restoreBodyScrolling() {
    if (typeof document !== 'undefined') {
      this.appWrapper = document.getElementById('body');
      this.appWrapper.style.removeProperty('position');
      this.appWrapper.style.removeProperty('top');
      /* eslint-disable no-multi-assign */
      document.documentElement.scrollTop = document.body.scrollTop = this.bodyScrollPos;
    }
  }

  render() {
    const { isMenuOpen } = this.state;
    const { children } = this.props;
    if (isMenuOpen) this.lockBodyScrolling();
    if (!isMenuOpen) this.restoreBodyScrolling();
    return (
      <ModalContext.Provider value={this.state}>
        {children}
        <Menu />
      </ModalContext.Provider>
    );
  }
}

ModalProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default ModalProvider;
