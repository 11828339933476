/* eslint-disable global-require, max-len */
export default {
  id: 'hangarEden',
  path: '/hangar-eden',
  nextPath: '/recreation-now',
  aspectRatio: '66.67',
  images: [
    {
      url: require('../assets/images/hangarEden/fabianschroder-hangar_eden-tropical_rainforest_1.jpg'),
      urlMedium: require('../assets/images/hangarEden/fabianschroder-hangar_eden-tropical_rainforest_1-medium.jpg'),
      // caption: 'Tropical Rainforest #1, 2013',
    }, {
      url: require('../assets/images/hangarEden/fabianschroder-hangar_eden-rooms_with_style.jpg'),
      urlMedium: require('../assets/images/hangarEden/fabianschroder-hangar_eden-rooms_with_style-medium.jpg'),
      // caption: 'Rooms with style, 2013',
    }, {
      url: require('../assets/images/hangarEden/fabianschroder-hangar_eden-tropical_sea_1.jpg'),
      urlMedium: require('../assets/images/hangarEden/fabianschroder-hangar_eden-tropical_sea_1-medium.jpg'),
      // caption: 'Tropical Sea #1, 2013',
    // }, {
    //   url: require('../assets/images/hangarEden/fabianschroder-hangar_eden-palm_beach.jpg'),
    //   urlMedium: require('../assets/images/hangarEden/fabianschroder-hangar_eden-palm_beach-medium.jpg'),
    //   caption: 'Palm Beach, 2013'
    }, {
      url: require('../assets/images/hangarEden/fabianschroder-hangar_eden-royal_harbour_2.jpg'),
      urlMedium: require('../assets/images/hangarEden/fabianschroder-hangar_eden-royal_harbour_2-medium.jpg'),
      // caption: 'Royal Harbour #2, 2013',
    }, {
      url: require('../assets/images/hangarEden/fabianschroder-hangar_eden-tropical_village.jpg'),
      urlMedium: require('../assets/images/hangarEden/fabianschroder-hangar_eden-tropical_village-medium.jpg'),
      // caption: 'Tropical Village, 2013',
    }, {
      url: require('../assets/images/hangarEden/fabianschroder-hangar_eden-tropical_sea_2.jpg'),
      urlMedium: require('../assets/images/hangarEden/fabianschroder-hangar_eden-tropical_sea_2-medium.jpg'),
      // caption: 'Tropical Sea #2, 2013',
    }, {
      url: require('../assets/images/hangarEden/fabianschroder-hangar_eden-tropical_sea_3.jpg'),
      urlMedium: require('../assets/images/hangarEden/fabianschroder-hangar_eden-tropical_sea_3-medium.jpg'),
      // caption: 'Tropical Sea #3, 2014',
    }, {
      url: require('../assets/images/hangarEden/fabianschroder-hangar_eden-2high_hot_air_balloon.jpg'),
      urlMedium: require('../assets/images/hangarEden/fabianschroder-hangar_eden-2high_hot_air_balloon-medium.jpg'),
      // caption: '2high hot air balloon, 2014',
    }, {
      url: require('../assets/images/hangarEden/fabianschroder-hangar_eden-royal_harbour_1.jpg'),
      urlMedium: require('../assets/images/hangarEden/fabianschroder-hangar_eden-royal_harbour_1-medium.jpg'),
      // caption: 'Royal Harbour #1, 2013',
    }, {
      url: require('../assets/images/hangarEden/fabianschroder-hangar_eden-tropical_rainforest_2.jpg'),
      urlMedium: require('../assets/images/hangarEden/fabianschroder-hangar_eden-tropical_rainforest_2-medium.jpg'),
      // caption: 'Tropical Rainforest #2, 2014',
    }, {
      url: require('../assets/images/hangarEden/fabianschroder-hangar_eden-rainforest_camp.jpg'),
      urlMedium: require('../assets/images/hangarEden/fabianschroder-hangar_eden-rainforest_camp-medium.jpg'),
      // caption: 'Rainforest Camp, 2014',
    }, {
      url: require('../assets/images/hangarEden/fabianschroder-hangar_eden-tropical_sea_4.jpg'),
      urlMedium: require('../assets/images/hangarEden/fabianschroder-hangar_eden-tropical_sea_4-medium.jpg'),
      // caption: 'Tropical Sea #4, 2014',
    }, {
    //   url: require('../assets/images/hangarEden/fabianschroder-hangar_eden-hangar_2_jpg'),
    //   urlMedium: require('../assets/images/hangarEden/fabianschroder-hangar_eden-hangar_2_medium.jpg'),
    //   caption: 'Hangar #2, 2013'
    // }, {
      url: require('../assets/images/hangarEden/fabianschroder-hangar_eden-hangar_3.jpg'),
      urlMedium: require('../assets/images/hangarEden/fabianschroder-hangar_eden-hangar_3-medium.jpg'),
      // caption: 'Hangar #1, 2013',
    }, {
      url: require('../assets/images/views/fabianschroder-views-berlin_somos.jpg'),
      urlMedium: require('../assets/images/views/fabianschroder-views-berlin_somos-medium.jpg'),
      caption: 'Tropicana 404, Somos Arts, Berlin, DE, 2015',
      link: 'http://www.somos-arts.org/tropicana-404',
      isGalleryImage: true,
    }, {
      url: require('../assets/images/views/fabianschroder-views-vienna_raumteiler_3.jpg'),
      urlMedium: require('../assets/images/views/fabianschroder-views-vienna_raumteiler_3-medium.jpg'),
      caption: 'Recreation Now & Hangar Eden, Raumteiler Vienna, AT, 2014',
      link: 'https://dieraumteiler.wordpress.com/portfolio/ausstellung-fabian-schroder/',
      isGalleryImage: true,
    }, {
      url: require('../assets/images/views/fabianschroder-views-giessen_grunbergerstr.jpg'),
      urlMedium: require('../assets/images/views/fabianschroder-views-giessen_grunbergerstr-medium.jpg'),
      caption: 'Pop Magazin 5 Release / Recreation Now & Hangar Eden, Grünberger Str. 22, Gießen, DE, 2014',
      link: 'http://gruenbergerstr22.tumblr.com/',
      isGalleryImage: true,
    },
  ],
};
