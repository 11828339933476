/* eslint-disable global-require, max-len */
export default {
  id: 'recreationNow',
  path: '/recreation-now',
  nextPath: '/in-natura',
  aspectRatio: '70.67',
  images: [
    {
      url: require('../assets/images/recreationNow/fabianschroder-recreation_now-breskens_2.jpg'),
      urlMedium: require('../assets/images/recreationNow/fabianschroder-recreation_now-breskens_2-medium.jpg'),
      // caption: 'Breskens #2, 2012',
    }, {
      url: require('../assets/images/recreationNow/fabianschroder-recreation_now-de_banjaard_7.jpg'),
      urlMedium: require('../assets/images/recreationNow/fabianschroder-recreation_now-de_banjaard_7-medium.jpg'),
      // caption: 'De Banjaard #7, 2012',
    }, {
      url: require('../assets/images/recreationNow/fabianschroder-recreation_now-de_haan_2.jpg'),
      urlMedium: require('../assets/images/recreationNow/fabianschroder-recreation_now-de_haan_2-medium.jpg'),
      // caption: 'De Haan #2, 2012',
    }, {
      url: require('../assets/images/recreationNow/fabianschroder-recreation_now-de_banjaard_1.jpg'),
      urlMedium: require('../assets/images/recreationNow/fabianschroder-recreation_now-de_banjaard_1-medium.jpg'),
      // caption: 'De Banjaard #1, 2012',
    }, {
      url: require('../assets/images/recreationNow/fabianschroder-recreation_now-de_banjaard_3.jpg'),
      urlMedium: require('../assets/images/recreationNow/fabianschroder-recreation_now-de_banjaard_3-medium.jpg'),
      // caption: 'De Banjaard #3, 2012',
    }, {
      url: require('../assets/images/recreationNow/fabianschroder-recreation_now-de_banjaard_2.jpg'),
      urlMedium: require('../assets/images/recreationNow/fabianschroder-recreation_now-de_banjaard_2-medium.jpg'),
      // caption: 'De Banjaard #2, 2012',
    }, {
      url: require('../assets/images/recreationNow/fabianschroder-recreation_now-de_haan_1.jpg'),
      urlMedium: require('../assets/images/recreationNow/fabianschroder-recreation_now-de_haan_1-medium.jpg'),
      // caption: 'De Haan #1, 2012',
    }, {
      url: require('../assets/images/recreationNow/fabianschroder-recreation_now-cochem_1.jpg'),
      urlMedium: require('../assets/images/recreationNow/fabianschroder-recreation_now-cochem_1-medium.jpg'),
      // caption: 'Cochem #1, 2012',
    }, {
    //   url: require('../assets/images/recreationNow/fabianschroder-recreation_now-de_banjaard_6.jpg'),
    //   urlMedium: require('../assets/images/recreationNow/fabianschroder-recreation_now-de_banjaard_6-medium.jpg'),
    //   caption: 'De Banjaard #6, 2012'
    // }, {
      url: require('../assets/images/recreationNow/fabianschroder-recreation_now-scharendijke_1.jpg'),
      urlMedium: require('../assets/images/recreationNow/fabianschroder-recreation_now-scharendijke_1-medium.jpg'),
      // caption: 'Scharendijke #1, 2012',
    }, {
      url: require('../assets/images/recreationNow/fabianschroder-recreation_now-buitenplaats_1.jpg'),
      urlMedium: require('../assets/images/recreationNow/fabianschroder-recreation_now-buitenplaats_1-medium.jpg'),
      // caption: 'Buitenplaats #1, 2012',
    }, {
      url: require('../assets/images/recreationNow/fabianschroder-recreation_now-de_banjaard_5.jpg'),
      urlMedium: require('../assets/images/recreationNow/fabianschroder-recreation_now-de_banjaard_5-medium.jpg'),
      // caption: 'De Banjaard #5, 2012',
    }, {
      url: require('../assets/images/recreationNow/fabianschroder-recreation_now-breskens_1.jpg'),
      urlMedium: require('../assets/images/recreationNow/fabianschroder-recreation_now-breskens_1-medium.jpg'),
      // caption: 'Breskens #1, 2012',
    }, {
      url: require('../assets/images/recreationNow/fabianschroder-recreation_now-bruinisse_1.jpg'),
      urlMedium: require('../assets/images/recreationNow/fabianschroder-recreation_now-bruinisse_1-medium.jpg'),
      // caption: 'Bruinisse #1, 2012',
    }, {
      url: require('../assets/images/recreationNow/fabianschroder-recreation_now-de_banjaard_4.jpg'),
      urlMedium: require('../assets/images/recreationNow/fabianschroder-recreation_now-de_banjaard_4-medium.jpg'),
      // caption: 'De Banjaard #4, 2012',
      aspectRatio: '66.67',
    }, {
      url: require('../assets/images/views/fabianschroder-views-vienna_raumteiler_1.jpg'),
      urlMedium: require('../assets/images/views/fabianschroder-views-vienna_raumteiler_1-medium.jpg'),
      caption: 'Recreation Now & Hangar Eden, Raumteiler Vienna, AT, 2014',
      link: 'https://dieraumteiler.wordpress.com/portfolio/ausstellung-fabian-schroder/',
      isGalleryImage: true,
      aspectRatio: '66.67',
    }, {
      url: require('../assets/images/views/fabianschroder-views-vienna_raumteiler_2.jpg'),
      urlMedium: require('../assets/images/views/fabianschroder-views-vienna_raumteiler_1-medium.jpg'),
      caption: 'Recreation Now & Hangar Eden, Raumteiler Vienna, AT, 2014',
      link: 'https://dieraumteiler.wordpress.com/portfolio/ausstellung-fabian-schroder/',
      isGalleryImage: true,
      aspectRatio: '66.67',
    }, {
      url: require('../assets/images/views/fabianschroder-views-leiden_fotofestival.jpg'),
      urlMedium: require('../assets/images/views/fabianschroder-views-leiden_fotofestival-medium.jpg'),
      caption: 'Een bespiegeling van het hedendaagse leven, Fotofestival Leiden, NL, 2013',
      link: 'http://internationalphotofestivalleiden.com/?page_id=744',
      isGalleryImage: true,
      aspectRatio: '66.67',
    },
  ],
};
