import React from 'react';
import styled from 'styled-components';
import { Location } from '@reach/router';
import { useTranslation } from 'react-i18next';

import { widthBp, sizes } from '../lib/styles';
import { ModalConsumer } from './ModalProvider';
import colors from '../lib/colors';

const Letter = styled.h1`
  z-index: 3;
  display: inline-block;
  position: fixed;
  left: 30px;
  top: 20px;
  margin: 0;
  white-space: nowrap;
  text-transform: uppercase;
  font-weight: 700;
  font-size: 36px;
  font-family: 'Roboto', sans-serif;
  letter-spacing: -0.01em;
  user-select: none;
  transition: opacity 0.18s ease-in-out;
  background: white;
  color: black;
  @media (prefers-color-scheme: light) {
    background: white;
    color: black;
  }
  @media (prefers-color-scheme: dark) {
    background: ${colors.dark.background};
    color: white;
  }
  pointer-events: ${props => (props.isHomeRoute ? 'none' : 'all')};
  cursor: ${props => (props.isHomeRoute ? 'default' : 'pointer')};
  @media (${widthBp(sizes.laptop)}) {
    top: 60px;
    font-size: 50px;
    left: inherit;
    transform-origin: top left;
    transform: rotate(-90deg);
    top: 515px;
    font-size: 48px;
    background: none;
    color: black;
    @media (prefers-color-scheme: light) {
      background: none;
      color: black;
    }
    @media (prefers-color-scheme: dark) {
      background: none;
      color: white; 
    }
  }
`;

const Title = () => {
  const { t } = useTranslation();
  return (
    <Location>
      {({ location }) => {
        const isHomeRoute = location.pathname === '/';
        return (
          <ModalConsumer>
            {({ isMenuOpen, showMenu, hideMenu }) => (
              <Letter
                onClick={(isMenuOpen && !isHomeRoute) ? hideMenu : showMenu}
                isHomeRoute={isHomeRoute}
                isMenuOpen={isMenuOpen}
              >
                {t('about.me.name')}
              </Letter>
            )}
          </ModalConsumer>
        );
      }}
    </Location>
  );
};

export default Title;
