import React from 'react';
import styled from 'styled-components';
import { Link, Location } from '@reach/router';
import { useTranslation } from 'react-i18next';
import i18next from 'i18next';

import { widthBp, sizes } from '../../lib/styles';
import { ModalConsumer } from '../ModalProvider';
import about from '../../content/about';
import legal from '../../content/legal';
import plasticHabitat from '../../content/plasticHabitat';
import hangarEden from '../../content/hangarEden';
import recreationNow from '../../content/recreationNow';
import inNatura from '../../content/inNatura';
import anthropos from '../../content/anthropos';
// import surface from '../../content/surface';

const projects = [
  // {
  //   id: surface.id,
  //   to: surface.path,
  // },
  {
    id: inNatura.id,
    to: inNatura.path,
  },
  {
    id: anthropos.id,
    to: anthropos.path,
  },
  {
    id: plasticHabitat.id,
    to: plasticHabitat.path,
  },
  {
    id: hangarEden.id,
    to: hangarEden.path,
  },
  {
    id: recreationNow.id,
    to: recreationNow.path,
  },
];

const MenuContent = () => {
  const { t } = useTranslation();
  const info = [
    {
      name: t('about.title'),
      to: about.path,
    },
    {
      name: t('legal.title'),
      to: legal.path,
    },
  ];

  const handleLanguageChange = () => {
    const currentLanguage = i18next.language;
    const futureLanguage = currentLanguage.startsWith('en') ? 'de' : 'en';
    i18next.changeLanguage(futureLanguage);
  };
  return (
    <Location>
      {({ location }) => {
        const isHomeRoute = location.pathname === '/';
        return (
          <ModalConsumer>
            {({ hideMenu }) => (
              <NavWrap>
                <Label>{t('projects.title')}</Label>
                <NavList>
                  {projects.map(({ to, id }) => (
                    <NavElementWrap key={to}>
                      <NavElement
                        onClick={hideMenu}
                        to={to}
                      >
                        {t(`projects.${id}.title`)}
                      </NavElement>
                    </NavElementWrap>
                  ))
                  }
                </NavList>
                <Label>{t('projects.info')}</Label>
                <NavList>
                  {info.map(entity => (
                    <NavElementWrap key={entity.to}>
                      <NavElement
                        key={entity.to}
                        onClick={hideMenu}
                        to={entity.to}
                      >
                        {entity.name}
                      </NavElement>
                    </NavElementWrap>
                  ))
                  }
                </NavList>
                <LanguageSwitch isHomeRoute={isHomeRoute} onClick={handleLanguageChange}>{t('projects.language')}</LanguageSwitch>
              </NavWrap>
            )
          }
          </ModalConsumer>
        );
      }}
    </Location>
  );
};

const NavWrap = styled.section`
  margin: 0;
  padding: 90px 30px 30px;
  width: 100%;
  @media (${widthBp(sizes.handheld)}) {
    padding-right: 110px;
  }
  @media (${widthBp(sizes.laptop)}) {
    width: auto;
    padding-left: calc((100vw - 1000px)/2 + 96px);
  }
  @media (${widthBp(sizes.desktop)}) {
    padding-left: calc((100vw - 1150px)/2 + 96px);
  }
  @media (${widthBp(sizes.huge)}) {
    padding-left: calc((100vw - 1250px)/2 + 96px);
  }
`;

const NavList = styled.ul`
  padding: 0;
  margin-bottom: 40px;
`;

const NavElement = styled(Link)`
  display: block;
  width: fit-content;
  font-size: 32px;
  line-height: 1.27;
  margin-top: 0;
  font-weight: 100;
  text-decoration: none;
  user-select: none;
  @media (${widthBp(sizes.laptop)}) {
    font-size: 38px;
    line-height: 1.23;
    &:hover, &:focus {
      background-color: black;
      color: white;
    }
    @media (prefers-color-scheme: light) {
      &:hover, &:focus {
        background-color: black;
        color: white;
      }
    }
    @media (prefers-color-scheme: dark) {
      &:hover, &:focus {
        background-color: white;
        color: black;
      }
    }
  }
`;

const NavElementWrap = styled.li`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: baseline;
`;

const Label = styled.label`
  display: block;
  opacity: 0.75;
  margin-bottom: 8px;
  font-size: 23px;
  font-weight: 100;
  font-family: 'Roboto', sans-serif;
  @media (${widthBp(sizes.laptop)}) {
    font-size: 27px;
    /* margin-bottom: 12px; */
  }
`;

const LanguageSwitch = styled.button`
  ${({ isHomeRoute }) => !isHomeRoute && 'right: 40px;'};
  font-size: 27px;
  color: black;
  margin: 0;
  width: fit-content;
  border: none;
  background: none;
  z-index: 0;
  cursor: pointer;
  outline: none;
  padding: 0;
  margin-bottom: 120px;
  @media (prefers-color-scheme: light) {
    color: black;
  }
  @media (prefers-color-scheme: dark) {
    color: white;
  }
  @media (${widthBp(sizes.laptop)}) {
    margin-bottom: 0;
    &:hover, &:focus {
      background-color: black;
      color: white;
    }
    @media (prefers-color-scheme: light) {
      &:hover, &:focus {
        background-color: black;
        color: white;
      }
    }
    @media (prefers-color-scheme: dark) {
      &:hover, &:focus {
        background-color: white;
        color: black;
      }
    }
  }
`;

export default MenuContent;
